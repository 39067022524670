import { useContext } from "react";
import TotalInfoBox from "./TotalInfoBox";
import TopInfoSection from "./TopInfoSection";
import MainButtonItem from "./MainButtonItem";
import { numberWithCommas } from "../utils/util";
import BlockchainContext from "../store/BlockchainContext";
import HistoryModalContext from "../store/HistoryModalContext";
import LastDepositItem from "./LastDepositItem";
import StakeItem from "./StakeItem";
const MainContentSection = () => {
  const {
    totalDeposit,
    totalRefEarn,
    usdPrice,
    account,
    walletBalance,
    harvestValue,
    handleWithdraw,
    lastDeposits,
  } = useContext(BlockchainContext);
  const { showModal } = useContext(HistoryModalContext);

  return (
    <section className="main-content-section">
      <div className="container">
     
        <TopInfoSection />
        <div className="main-content-holder d-flex row justify-content-evenly">
          <div className="left-main-section col-xl-4">
            <div className="total-info-holder d-flex flex-column gap-4">
            <img src="https://cryptopotato.com/wp-content/uploads/2018/10/bnb-min.jpg"  className="imageborder"/> 
              <TotalInfoBox
                title="Total Value Deposited"
                value={numberWithCommas(totalDeposit, 5)}
                dollor={numberWithCommas(totalDeposit * usdPrice, 2)}
              />
              <TotalInfoBox
                title="Total Referral Earnings"
                value={numberWithCommas(totalRefEarn, 5)}
                dollor={numberWithCommas(totalRefEarn * usdPrice, 2)}
              />
            </div>
            <div className="main-button-holder d-flex flex-column  gap-3">
              <MainButtonItem title="Affiliate Program" url="/#affiliate" blank={false} />
              <MainButtonItem title="Information" url="https://docs.bnbstake.io" blank={true} />
              <MainButtonItem
                title="Verified Contract"
                url={process.env.REACT_APP_CONTRACT_EXPLORERLINK+`address/${process.env.REACT_APP_CONTRACT}#code`}
                blank={true}
              />
              <MainButtonItem title="Telegram" url="https://t.me/bnbstake" blank={true} />
            </div>
          </div>
          <div className="right-main-section1 h-100 col-xl-7 ">
            <div className="farm-information-box w-100 mb-3">
              <h2 className="farm-head ps-4">Your Farm</h2>
              <div className="farm-item d-flex flex-wrap ps-4">
                <div className="farm-inner-item d-flex flex-column">
                  <p className="farm-item-title">BNB to Harvest:</p>
                  <div className="farm-middle d-flex align-items-center justify-content-between">
                    <p className="farm-middle-value">
                      {numberWithCommas(harvestValue, 5)} BNB
                    </p>
                    <button
                      className="farm-middle-btn harvest"
                      onClick={() => handleWithdraw()}
                    >
                      Withdraw
                    </button>
                  </div>
                  <p className="farm-dollor">
                    $ {numberWithCommas(harvestValue * usdPrice, 2)}
                  </p>
                </div>
                <div className="farm-inner-item d-flex flex-column">
                  <p className="farm-item-title">BNB in Wallet:</p>
                  <div className="farm-middle d-flex align-items-center justify-content-between">
                    <p className="farm-middle-value">
                      {numberWithCommas(walletBalance, 5)} BNB
                    </p>
                    <button
                      className="farm-middle-btn hervest"
                      onClick={() => account ?showModal() : null}
                    >
                      History
                    </button>
                  </div>
                  <p className="farm-dollor">
                    $ {numberWithCommas(walletBalance * usdPrice, 2)}
                  </p>
                </div>
              </div>
            </div>
            <div className="left-stake-holder">
              {/* <div className="left-stake-section">
                <h1 className="stake-head">Stake BNB</h1>
                <p className="min-text">Minimum Stake Amount: 0.05 BNB</p>
                <div className="stake-head d-flex align-items-center justify-content-between mt-5 ps-2">
                  <p className="stake-head-title">days</p>
                  <p className="stake-head-title">%daily</p>
                  <p className="stake-head-title">total</p>
                  <p className="stake-head-title"></p>
                </div>
                <div className="d-flex flex-column mt-4 ps-2">
                  
                  <StakeItem days="50" daily="4%" total="200%" plan={1} />
                  <StakeItem days="30" daily="5%" total="150%" plan={0} />
                </div>
              </div> */}
              <div className="right-main-section">
                <h2 className="last-deposit-head">Last Deposits</h2>
                <div className="deposit-holder d-flex flex-column gap-3" id="ds">
                      {lastDeposits
                        ? lastDeposits.map((deposit, index) => (
                            <LastDepositItem key={index} deposit={deposit} />
                          ))
                        : null}
                </div>
            </div>
            <div className="read-more-box">
              <h1 className="read-title">Read before use</h1>
              <p className="read-body">
                The principal deposit cannot be withdrawn, the only return users
                can get are daily dividends and referral rewards. Payments is
                possible only if contract balance have enough BNB. Please
                analyze the transaction history and balance of the smart
                contract before investing. High risk - high profit, DYOR
              </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainContentSection;

import secret from "../assets/img/user-secret.svg";
import chart from "../assets/img/chart-icon.svg";
import support from "../assets/img/support-icon.svg";
import FooterItem from "./FooterItem";
const FooterMainSection = () => {
  return (
    <div className="footer-main-section py-5">
      <div className="container">
        <div className="footer-main-top text-center pb-5">
          <h2>Start your yield farm journey</h2>
          <p>with BNB Stake Community</p>
        </div>
        <div className="footer-item-holder row">
          <FooterItem
            image={secret}
            title="Ironclad security"
            text="The smart contract code has been successful audited by 2
          independent companies"
          />
          <FooterItem
            image={chart}
            title="High & Stable APR"
            text="In the code sets the highest APR among all yield farms on BSC, rules of a smart contract can’t be changed, nothing can affect the amount of income."
          />
          <FooterItem
            image={support}
            title="Customer Support"
            text="BNB Stake 24/7 provides you our knowledgable
 and experienced customer support team in Telegram"
          />
        </div>
      </div>
    </div>
  );
};

export default FooterMainSection;

// import haze from "../assets/img/haze.png";
import certik from "../assets/img/certik.png";
import dappradar from "../assets/img/dappradar.png";
import twittericon from "../assets/img/twitter.png";
import telegram from "../assets/img/telegram.png";

const FooterBottomSection = () => {
  return (
    <section className="footer-bottom-section w-100 py-4">
      <div className="container">
        <div className="footer-audit-box d-flex flex-wrap justify-content-evenly align-items-center py-2">
          {/* <a target='_blank' href='https://hazecrypto.net/BNBNEO'><img src={haze} alt="haze crypto" className="footer-bottom-img" /></a> */}
          <img src={certik} alt="certik" className="footer-bottom-img" />
          <a target='_blank' ><img src={dappradar} alt="dapp radar" className="footer-bottom-img" /></a>
          <a target='_blank' href='https://twitter.com/bnbstake'><img src={twittericon} alt="twitter" className="footer-bottom-img social" /></a>
          <a target='_blank' href='https://t.me/bnbstake'><img src={telegram} alt="telegram" className="footer-bottom-img social" /></a>
        </div>
        <p className="footer-BNBNEO">
          BNB Stake <span>BLOCKCHAIN INVESTMENT PLATFORM </span>
        </p>
        <p className="footer-BNBNEO-bottom">©bnbstake.io, 2021</p>
      </div>
    </section>
  );
};

export default FooterBottomSection;
